import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from '#/utils/axios';
import { JWTContextType, AuthUser, ActionMap, AuthState } from '#/types/auth';
import { isValidToken, setSession } from '#/utils/jwt';
import queryClient from '#/api/query-client';
import useLocales from '#/hooks/useLocales';

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions): AuthState => {
  switch (action.type) {
    case Types.Initial:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case Types.Login:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case Types.Logout:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case Types.Register:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const { translate } = useLocales();
  const navigate = useNavigate();

  // Function to initialize authentication
  const initialize = useCallback(async (accessToken?: string) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('accessToken') || accessToken;
      if (token && isValidToken(token)) {
        setSession(token);
        const { data: userDetails } = await axios.get('/users/profile/');
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user: userDetails,
          },
        });
      } else {
        setSession(null);
        localStorage.removeItem('accessToken');
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Function to handle login
  const login = useCallback(
    async (params: any) => {
      setIsLoading(true);
      try {
        const { data } = await axios.post('/users/login/', params);
        const { access, OTP } = data;

        if (OTP) {
          navigate('/auth/verifizieren', { state: { email: params.email } });
        } else {
          setSession(access);
          const { data: userDetails } = await axios.get('/users/profile/');
          dispatch({
            type: Types.Login,
            payload: { user: userDetails },
          });
          toast.success(String(translate('toast_notifications.success.login')));
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [navigate, translate]
  );

  // Function to handle login using a token
  const loginFromToken = useCallback(
    async (params: any, baseUrl = 'successor') => {
      setIsLoading(true);
      try {
        const { data: user } = await axios.post(
          `/${baseUrl}/accept-invitation/`,
          { token: params }
        );
        setSession(user.token);

        const { data: userDetails } = await axios.get('/users/profile/');
        dispatch({
          type: Types.Login,
          payload: { user: userDetails },
        });
        toast.success(String(translate('toast_notifications.success.login')));
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [translate]
  );

  // Function to handle user registration
  const register = useCallback(
    async (params: any) => {
      setIsLoading(true);
      try {
        const { data: user } = await axios.post('/users/register/', params);
        setSession(user.token);

        const { data: userDetails } = await axios.get('/users/profile/');
        dispatch({
          type: Types.Register,
          payload: { user: userDetails },
        });
        toast.success(
          String(translate('toast_notifications.success.register'))
        );
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [translate]
  );

  // Function to handle logout
  const logout = useCallback(async () => {
    setSession(null);
    localStorage.clear();
    dispatch({ type: Types.Logout });
    queryClient.clear();
    toast.success(String(translate('toast_notifications.error.login')));
  }, [translate]);

  // Function to refetch user data
  const refetch = useCallback(async () => {
    try {
      const { data: userDetails } = await axios.get('/users/profile/');
      dispatch({
        type: Types.Login,
        payload: { user: userDetails },
      });
    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);

  // Memoized context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      ...state,
      isLoading,
      login,
      loginFromToken,
      initialize,
      logout,
      register,
      refetch,
    }),
    [
      state,
      isLoading,
      login,
      loginFromToken,
      initialize,
      logout,
      register,
      refetch,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
