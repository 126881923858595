import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useAuth from '#/hooks/useAuth';
import { AuthUser, User } from '#/types/auth';
import { useListMyConnections, useSwitchAsGuest } from '#/api/successor';
import useLocalStorage from '#/hooks/useLocalStorage';

type AccountSwitchContextProps = {
  currentAccount: CurrentAccount | AuthUser | undefined;
  handleSwitchAccount: (account: AuthUser) => void;
  refetchAccount: (id: number) => void;
  isLoading?: boolean;
};

const AccountSwitchContext = createContext<AccountSwitchContextProps>({
  currentAccount: undefined,
  handleSwitchAccount: () => {},
  refetchAccount: () => {},
  isLoading: false,
});

type AccountSwitchProviderProps = {
  children: React.ReactNode;
};

export interface CurrentAccount extends User {
  docs: {
    reported_as_dead: boolean;
    reported_as_dead_status: string;
    downloaded: [];
    living_will: object;
    power_of_attorney: object;
    last_will: object;
  };
  invitation_details: {
    general_access_right: string;
    has_special_access: boolean;
    specific_service_access?: {
      service: string;
      accessLevel: string;
    }[];
  };
}

const AccountSwitchProvider: React.FC<AccountSwitchProviderProps> = ({
  children,
}) => {
  const [currentAccount, setCurrentAccount] = useState<
    CurrentAccount | AuthUser
  >();

  const [guestOn, setLocalStorageGuestOn] = useLocalStorage('guest_on', null);
  const { user, isInitialized } = useAuth();
  const { mutateAsync: switchAsGuest } = useSwitchAsGuest();
  const { data: connectionsList, isLoading } = useListMyConnections(
    !!user && isInitialized
  );

  const switchAccountById = useCallback(
    async (id: number) => {
      const res = await switchAsGuest({ id });
      setCurrentAccount(res?.data);
    },
    [switchAsGuest]
  );

  useEffect(() => {
    if (guestOn) {
      switchAccountById(Number(guestOn));
    } else {
      setCurrentAccount(user);
    }
  }, [guestOn]);

  const handleSwitchAccount = useCallback((account: AuthUser) => {
    setLocalStorageGuestOn(account?.id);
    setCurrentAccount(account);
  }, []);

  const refetchAccount = useCallback(
    async (id: number) => {
      await switchAccountById(id);
    },
    [switchAccountById]
  );

  useEffect(() => {
    if (guestOn && connectionsList) {
      switchAccountById(Number(guestOn));
    } else if (
      !user?.primary_account &&
      connectionsList?.length &&
      guestOn === null
    ) {
      switchAccountById(connectionsList[0]?.id);
      setLocalStorageGuestOn(connectionsList[0]?.id);
    } else {
      setCurrentAccount(user);
    }
  }, [guestOn, connectionsList, user?.primary_account]);

  const contextValue = useMemo(
    () => ({
      currentAccount,
      handleSwitchAccount,
      refetchAccount,
      isLoading,
    }),
    [currentAccount, handleSwitchAccount, refetchAccount, isLoading]
  );

  return (
    <AccountSwitchContext.Provider value={contextValue}>
      {children}
    </AccountSwitchContext.Provider>
  );
};

export { AccountSwitchProvider, AccountSwitchContext };
