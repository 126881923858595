const pensionProvision = {
  title:
    'gut geregelt: Wir helfen Ihnen, Ihre Liebsten und sich selbst zu schützen',
  alreadyHaveAcc: 'Bereits ein Konto?',
  signIn: 'Anmelden',
  p1: 'Das gut geregelt-Team steht Ihnen bei wichtigen Themen zur Verfügung, die jeder angehen sollte. Wir beginnen mit drei wichtigen Themen für die Ruhestands- und Vorsorgeplanung und werden unser Angebot kontinuierlich erweitern, um Ihren Bedürfnissen gerecht zu werden.',
  p2: 'Wir begleiten Sie ähnlich wie ein persönlicher Trainer bei wichtigen Prozessen, die Sie angehen müssen, damit Sie in schwierigen Situationen sowohl für sich selbst als auch für Ihre Liebsten Probleme vermeiden können.',
  p3: 'Lassen Sie uns Einzelheiten besprechen. Wir empfehlen, dass jeder spätestens im Alter von 50 Jahren die folgenden rechtlichen Dokumente abgeschlossen und finalisiert haben sollte:',
  p3a: 'Wenn Sie jetzt starten und sich 30 Minuten Zeit nehmen, garantieren wir Ihnen, dass Sie gut geregelt sein werden.',
  p4: 'Basierend auf Ihren Antworten besteht ein geringes Risiko, dass Sie, wie die meisten unserer Kunden, die notwendigen Schritte nicht beim ersten Versuch unternehmen können, um Ihr Leben gut zu organisieren.',
  p4a: 'Die meisten unserer Kunden schaffen es nicht beim ersten Versuch, die notwendigen Schritte zu unternehmen, um ihr Leben gut zu regeln.',
  p5a: 'Um Sie hierbei zu unterstützen, bieten wir einen Erinnerungsservice an.',
  p5b: 'Dafür benötigen wir von Ihnen jetzt einige Daten, damit wir Sie an die offenen Schritte erinnern können.',
  p6a: 'Deshalb möchten wir Ihnen gerne unseren Erinnerungsservice als Unterstützung anbieten.',
  p6b: 'Bitte geben Sie uns jetzt einen Kommunikationskanal an, über den wir Sie an die offenen Schritte erinnern dürfen.',

  onboarding: {
    stepOne: {
      title: 'Vorsorge-Check',
      subtitle: 'Zunächst möchten wir Sie etwas besser kennenlernen',
      questions: [
        {
          question: 'Zunächst einmal: Für wen möchten Sie Vorsorge treffen?',
          options: [
            {
              label: 'Ich selbst',
            },
            {
              label: 'Familienmitglied',
            },
          ],
        },
        {
          question: '{{referringTo}}',
          options: [
            {
              label: 'Ja',
            },
            {
              label: 'Nein',
            },
          ],
        },
      ],
      theirRealEstate: 'Besitzt Ihr Familienmitglied Immobilien?',
      myRealEstate: 'Besitzen Sie Immobilien?',
    },
    stepTwo: {
      title: 'Vorsorge-Check',
      description: 'Was Menschen wie Sie haben, die gut vorgesorgt haben.',
      subtitlePersonal:
        'Vielen Dank, {{genderFormal}} {{first_name}} {{last_name}}! Wir empfehlen Leuten wie Ihnen, sicherzustellen, dass Sie die folgenden Artefakte oder Dienste haben oder in Betracht ziehen.',
      femaleGenderBased: 'liebe',
      maleGenderBased: 'lieber',
      subtitleOther:
        'Vielen Dank! Für Personen wie {{first_name}} {{last_name}} empfehlen wir, sicherzustellen, dass sie/er (basierend auf dem Geschlecht) oder die folgenden Artefakte oder Dienstleistungen in Betracht zieht',
      selectOnlyCompleted: 'Klicken Sie auf die, die Sie bereits haben.',
      seeResults: 'Ergebnisse anzeigen',
      noCompletedService: 'Ich habe keine der Optionen abgeschlossen',
    },
    stepThree: {
      title: 'Wie können wir helfen?',
      stepByStep: 'Schritt für Schritt zu einer besser organisierten Vorsorge.',
      redLightLabel: 'Sie haben noch einen weiten Weg vor sich.',
      yellowLightLabel: 'Es liegt Arbeit vor Ihnen!',
      greenLightLabel: 'Du bist gut geregelt',
      redLightRecommendation: {
        title:
          'Aber keine Sorge, wenn Sie einen Schritt nach dem anderen machen, werden Sie schnell ans Ziel kommen. Nehmen Sie uns beim Wort. Beginnen Sie einfach heute mit dem ersten Schritt.',
      },
      yellowLightRecommendation: {
        title:
          'Gut, Sie haben sich bereits um {{list}} gekümmert. Wir empfehlen Ihnen daher, mit den folgenden Dingen fortzufahren:',
      },
      greenLightRecommendation: {
        title:
          'Wow! Du hast bereits alles getan, was Leute wie du zumindest hätten tun sollen. Schau dir auch noch andere Themen an, die für dich einen Mehrwert bieten könnten.',
      },
      subtitle:
        'Die Erfahrung zeigt, dass Vorsorgefragen oft durch Lebensereignisse wie Heirat, Kinder oder das Alter ausgelöst werden.',
      provisionTopics: 'Vorsorgethemen',
      provisionTopicsOptions: {
        financialAdvise: 'Finanzberater',
        legalAdvise: 'Rechtsberatung',
      },
      lifeSituations: 'Lebenssituationen',
      lifeSituationsOptions: {
        marriage: 'Heirat',
        birthOfChild: 'Geburt eines Kindes',
        purchaseOfRealEstate: 'Kauf einer Immobilie',
        enteringRetirement: 'Eintritt in den Ruhestand',
        illnes: 'Krankheit',
        deathOfARelative: 'Tod eines Verwandten',
        foundingACompany: 'Gründung eines Unternehmens',
        divorce: 'Scheidung',
      },
    },
  },

  confirmCheckbox:
    'Durch Auswahl dieses Kontrollkästchens erkenne und bestätige ich an, dass ich zustimme, auf gut geregelt registriert zu werden.',
  processingAgreement: 'Siehe unsere Vereinbarung zur Auftragsbearbeitung.',

  c1: {
    title: 'Patientenverfügung',
    description:
      'ermöglicht es Ihnen, festzulegen, wie medizinische Fachleute im Notfall vorgehen sollen.',
  },
  c2: {
    title: 'Vorsorgevollmacht',
    description:
      'vermeidet Probleme mit dem KESB und regelt die Vormundschaft für Ihre Kinder.',
  },
  c3: {
    title: 'Testament',
    description: 'um Ihr Vermächtnis so zu hinterlassen, wie Sie es möchten.',
  },

  questions: [
    {
      question:
        'Wenn Sie die Wahl haben, etwas heute zu tun oder es auf morgen zu verschieben... Was tun Sie?',
      options: [
        {
          label: 'Ich erledige es sofort.',
        },
        {
          label: 'Ich weiss es nicht.',
        },
        {
          label: 'Ich verschiebe es auf morgen.',
        },
      ],
    },
    {
      question:
        'Wenn jemand versucht, Sie zu motivieren, etwas zu tun, das Ihnen nicht gefällt... Was funktioniert am besten?',
      options: [
        {
          label: 'Anreize setzen mich in Bewegung.',
        },
        {
          label: 'Ich weiss es nicht.',
        },
        {
          label: 'Es macht mich schlecht fühlen.',
        },
      ],
    },
    {
      question: 'Wie gehen Sie normalerweise an Dinge heran?',
      options: [
        {
          label: 'Augen zu und einfach machen.',
        },
        {
          label: 'Ich weiss es nicht.',
        },
        {
          label: 'Eher später.',
        },
      ],
    },
    {
      question:
        'Sagen Sie uns, wie oft Sie erinnert werden möchten, wenn Sie den Prozess vorübergehend pausieren:',
      options: [
        {
          label: 'Wöchentlich',
        },
        {
          label: 'Monatlich',
        },
        {
          label: 'Vierteljährlich',
        },
      ],
    },
  ],

  thankyouCard: {
    title: 'Einfache und unkomplizierte Vorsorge',
    thankYou: 'Vielen Dank für Ihr Interesse an gut geregelt',
    gentlyReminder:
      'Wir werden Sie sanft daran erinnern, bis Sie alles gut geregelt haben.',
  },
};

export default pensionProvision;
